<div class="main-content">
    <div class="main-card">
        <img routerLink="../.." class="back-button" src="../../../assets/icons/back.svg" alt="Back">
        <h2 class="title">Oops!</h2>
        <p class="sub-title">Something went wrong while unlocking the door. Please retry or enter the below code
            directly into a keypad.</p>
        <button
            type="button"
            mat-raised-button
            class="btn-primary unlock-button"
            mat-dialog-close
            (click)="onTapToUnlock()"
        >
            Tap to Unlock
        </button>

        <div *ngIf="entryCode">
            <app-entry-code [entryCode]="entryCode"></app-entry-code>
        </div>
    </div>
</div>

<app-footer></app-footer>
