import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GuestEventService} from "../../services/guest-event.service";
import {IEvent} from "../../interfaces/event.interface";
import {IAccessPoint} from "../../interfaces/access-point.interface";
import {RecurrenceTypeEnum} from "../../enums/recurrence-type.enum";
import * as moment from 'moment';
import {extendMoment} from 'moment-range';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../services/loader.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "../dialog/dialog.component";
import {SuccessDialogComponent} from "../success-dialog/success-dialog.component";
import {DataService} from "../../services/data.service";
import {errorsConstant} from "../../constants/errors.constant";
import {ENV} from 'src/app/env.token';
import {IEnvironment} from 'src/app/interfaces/environment.interface';
import {PassStatusEnum} from "../../enums/pass-status.enum";
import {PassTypeEnum} from "../../enums/pass-type.enum";

import * as converter from 'number-to-words';
import {PassSourceEnum} from "../../enums/pass-source.enum";
import {DOCUMENT} from "@angular/common";
import {AccessPointKindEnum} from "../../enums/access-point-kind.enum";
import {DockPassTypeEnum} from "../../enums/dock-pass-type.enum";
import {GeolocationComponent} from "../geolocation/geolocation.component";

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
    @ViewChild(GeolocationComponent) geolocationComponent: GeolocationComponent | undefined;

    event: IEvent | null = null;
    passId: string = '';
    recurrenceTypeEnum = RecurrenceTypeEnum;
    passStatusEnum = PassStatusEnum;
    passTypeEnum = PassTypeEnum;
    accessPointKindEnum = AccessPointKindEnum;
    dockPassTypeEnum = DockPassTypeEnum;
    currentPassStatus: string = '';
    chosenAccessPointName: string = '';
    errors = errorsConstant;
    addressStr: string = '';
    rangeMoment = extendMoment(moment);
    isCommercial = false;
    isEntryCodeAccessOnly: boolean = false;
    dockPassType: string = '';

    geolocationFeature: boolean = false;
    withinRadius: boolean = false;

    readonly notActiveDateFormat = 'MMMM Do [at] h:mm A';
    readonly API_KEY = 'AIzaSyDFQkp9ynJ8H0O8gtIhBjsbRDfCyWF3-00';

    constructor(public loaderService: LoaderService,
                public dialog: MatDialog,
                private guestEventService: GuestEventService,
                private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                @Inject(DOCUMENT) private readonly document: Document) {
    }

    public ngOnInit(): void {
        this.passId = this.route.snapshot.paramMap.get('id') || '';
        this.dockPassType = this.route.snapshot.firstChild?.data?.dockPassType;

        if (this.passId) {
            this.guestEventService.getEventDetails(this.passId, this.route.snapshot.firstChild?.data).subscribe(
                (res: IEvent) => {
                    this.event = res;
                    this.geolocationFeature = !!this.event?.config?.location_tracking;
                    this.calculatePassStatus(res);
                    this.getAddressStr();
                    this.setDateTime(res);
                    this.isEntryCodeAccessOnly = !this.event?.access_points.some((accessPoint: IAccessPoint) => !accessPoint.is_entry_code_access_only);
                    this.event.uiAccessPoints = this.event.access_points.map((point: IAccessPoint) => point.name).join(', ');
                    this.event.oldUITimeZone = this.event.community.time_zone.replace(/_/g, ' ').replace(/\//, ' \/ ');
                    this.event.uiTimeZone = moment.tz(this.event.community.time_zone).zoneAbbr();
                    this.isCommercial = this.event.event_source === PassSourceEnum.commercial;

                    if (this.isCommercial) {
                        this.document.title = 'myQ Docking Pass™';
                    }
                },
                err => this.guestEventService.handleError(this.passId, err.error.code));
        }
    }

    public ngOnDestroy(): void {
        localStorage.setItem('entryCode', this.event?.entry_code || '');
    }

    public onEntryCodeHelpLink(): void {
        this.dialog.open(DialogComponent, {
            ...this.dataService.dialogConfig, ...{
                data: {
                    title: 'Use Entry Code',
                    text: 'If you choose to use entry code, then make sure you select “I have an entry code” on the screen or enter the code directly into a keypad.',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }
            }
        });
    }

    public onDeviceClick(device: IAccessPoint): void {
        if (this.currentPassStatus === this.passStatusEnum.expired) {
            return;
        }

        if (this.geolocationFeature && !this.geolocationComponent?.entranceCanBeOpened()) {
            this.geolocationComponent?.showNotInProximityModal();
            return;
        }

        const modal: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent, {
            ...this.dataService.dialogConfig, ...{
                data: {
                    title: 'Confirm Unlock Entrance',
                    text: `For security reasons, please confirm that you are in close proximity to <b>${this.event?.community?.address?.address_line1}</b>`,
                    showCancelButton: true,
                    confirmButtonText: 'Confirm'
                }
            }
        });

        localStorage.setItem('accessPointName', device?.name || '');

        modal.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed && this.passId) {
                this.guestEventService.unlockDevice(this.passId, device.serial_number).subscribe(
                    () => this.openSuccessDialog(),
                    err => this.guestEventService.handleError(this.passId, err.error.code, device, this.currentPassStatus));
            }
        });
    }

    private getAddressStr(): void {
        const address = this.event?.community?.address;

        if (address) {
            let addressStr = `${address?.address_line1}, ${address?.city}, ${address?.state} ${address?.postal_code}, ${address?.country}`;

            addressStr = addressStr.replace(/\s/g, "+");
            this.addressStr = `https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${addressStr}`;
        }
    }

    private openSuccessDialog(): void {
        this.dialog.open(SuccessDialogComponent, {
            ...this.dataService.successDialogConfig,
            data: this.route.snapshot.firstChild?.data,
        });
    }

    private setDateTime(event: IEvent): void {
        const momentEndTime = moment(event.recurrence.start_time, 'HH:mm:ss').add(moment.duration(event.recurrence.duration));
        const oldDateFormat = 'MMM D, LT';
        const dateFormat = 'LL';

        event.uiStartDate = moment(event.recurrence.start_date + ' ' + event.recurrence.start_time).format(dateFormat);
        event.uiEndDate = moment(event.recurrence.end_date + ' ' + momentEndTime.format('HH:mm:ss')).format(dateFormat);

        event.uiStartTime = moment(event.recurrence.start_time, 'HH:mm:ss').format('LT');
        event.uiEndTime = momentEndTime.format('LT');

        if (event.recurrence.recurrence_type === this.recurrenceTypeEnum.once) {
            event.oldUIStartDate = moment(event.recurrence.start_date + ' ' + event.recurrence.start_time).format(oldDateFormat);
            event.oldUIEndDate = moment(event.recurrence.end_date + ' ' + momentEndTime.format('HH:mm:ss')).format(oldDateFormat);
        } else {
            event.uiWeeks = event.recurrence.weekly_days.map(day => moment(day, 'dddd').format('ddd')).join(', ');
        }

        if (event.type === this.passTypeEnum.delivery) {
            const passEndDate = moment(this.event?.recurrence.end_date);
            const daysLeft = passEndDate.diff(moment(), 'days') + 1;

            event.uiDeliveryRemainingDaysStr = this.getDeliveryRemainingDaysStr(daysLeft);
        }
    }

    private getDeliveryRemainingDaysStr(daysLeft: number): string {
        const deliveryRemainingDays = converter.toWords(daysLeft);
        const daysEnding = daysLeft > 1 ? 's' : '';

        return `Ends: In ${deliveryRemainingDays} day${daysEnding} or shortly after the first use.`;
    }

    private calculatePassStatus(event: IEvent): void {
        moment.tz.setDefault(event.community.time_zone);

        const isAllDayDuration = event.recurrence.duration === '1.00:00:00';
        const momentNowTimestamp = moment();
        const endTime = isAllDayDuration ? '23:59:59' : moment(event.recurrence.start_time, 'HH:mm:ss').add(moment.duration(event.recurrence.duration)).format('HH:mm:ss');
        const endDateTimeStamp = moment(event.recurrence.end_date + ' ' + endTime);
        const startDateTimeStamp = moment(event.recurrence.start_date + ' ' + event.recurrence.start_time);

        const isBeforePassEnd = momentNowTimestamp.isBefore(endDateTimeStamp);
        const isAfterPassStart = momentNowTimestamp.isAfter(startDateTimeStamp);

        if (event.recurrence.recurrence_type === this.recurrenceTypeEnum.weekly) {
            const currentWeekDay = momentNowTimestamp.format('dddd').toLowerCase();
            const isInRecurrenceDays = event.recurrence.weekly_days.includes(currentWeekDay);
            const occurrencesRange = this.rangeMoment.range(startDateTimeStamp, endDateTimeStamp);
            const nextOccurrences = Array.from(occurrencesRange.by('day'))
                .filter(m => event.recurrence.weekly_days.includes(m.format('dddd').toLowerCase()))
                .map(m => m.format());

            if (!isInRecurrenceDays) {
                this.setStatusAndNextOccurrence(event, nextOccurrences, momentNowTimestamp);
            } else {
                const timeFormat = 'HH:mm:ss';
                const currentTime = momentNowTimestamp.format(timeFormat);
                const isBeforePassTimeEnd = moment(currentTime, timeFormat).isBefore(moment(endTime, timeFormat));
                const isAfterPassTimeStart = moment(currentTime, timeFormat).isAfter(moment(event.recurrence.start_time, timeFormat));

                if (isAfterPassStart && isAfterPassTimeStart && isBeforePassTimeEnd) {
                    this.currentPassStatus = this.passStatusEnum.active;
                    localStorage.setItem('currentPassStatus', this.currentPassStatus || '');
                    localStorage.setItem('activeStartDate', this.event?.uiActiveStartDate || '');
                } else {
                    this.setStatusAndNextOccurrence(event, nextOccurrences, momentNowTimestamp);
                }
            }

            return;
        }

        event.uiActiveStartDate = `${startDateTimeStamp.format(this.notActiveDateFormat)} ${momentNowTimestamp.zoneName()}`;

        if (isAfterPassStart) {
            this.currentPassStatus = isBeforePassEnd ? this.passStatusEnum.active : this.passStatusEnum.expired;
        } else {
            this.currentPassStatus = this.passStatusEnum.notYetActive;
        }

        localStorage.setItem('activeStartDate', this.event?.uiActiveStartDate || '');
        localStorage.setItem('currentPassStatus', this.currentPassStatus || '');
    }

    private setStatusAndNextOccurrence(event: IEvent, nextOccurrences: string[], momentNowTimestamp: any): void {
        const nextOccurrence = nextOccurrences.find((date: string) => moment(date).isAfter());
        this.currentPassStatus = nextOccurrence ? this.passStatusEnum.notYetActive : this.passStatusEnum.expired;
        localStorage.setItem('currentPassStatus', this.currentPassStatus || '');

        if (nextOccurrence) {
            event.uiActiveStartDate = `${moment(nextOccurrence).format(this.notActiveDateFormat)} ${momentNowTimestamp.zoneName()}`;
            localStorage.setItem('activeStartDate', this.event?.uiActiveStartDate || '');
        }
    }
}
