import {Injectable} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public dialogConfig: MatDialogConfig = {
        maxWidth: '417px',
        width: '80vw',
        minHeight: '210px',
        height: '210px'
    };

    public successDialogConfig: MatDialogConfig = {
        maxWidth: '417px',
        width: '80vw',
        minHeight: '196px',
    };
}
