import {ErrorIconEnum} from "../enums/error-icon.enum";
import {IErrorPage} from "../interfaces/error-page.interface";
import {ErrorCodeEnum} from "../enums/error-code.enum";

export const errorsConstant: IErrorPage[] = [
    {
        code: ErrorCodeEnum.notFound,
        title: '404 Error',
        description: 'Page not found',
        iconName: ErrorIconEnum.error,
        routeName: '404',
        isDefault: true
    },
    {
        code: ErrorCodeEnum.expired,
        title: 'Pass Expired',
        description: 'Your guest pass has expired. Please contact the resident for assistance.',
        iconName: ErrorIconEnum.expired,
        routeName: 'expired',
        isDefault: false
    },
    {
        code: ErrorCodeEnum.canceled,
        title: 'Pass Canceled',
        description: 'Your guest pass is no longer active.  Please contact the resident for assistance.',
        iconName: ErrorIconEnum.expired,
        routeName: 'canceled',
        isDefault: false
    },
    {
        code: ErrorCodeEnum.outOfSchedule,
        title: 'Entryway out of schedule',
        description: '{accessPointName} is out of schedule and cannot be unlocked at this time. Please contact the resident for assistance.',
        iconName: ErrorIconEnum.error,
        wordToReplace: '{accessPointName}',
        routeName: 'out-of-schedule',
        isDefault: false
    },
    {
        code: ErrorCodeEnum.unableToUnlock,
        title: 'Unable to unlock entrance',
        description: 'Please use Entry Code instead',
        iconName: ErrorIconEnum.unable,
        routeName: 'unable-to-unlock',
        isDefault: false
    },
    {
        code: ErrorCodeEnum.passNotActive,
        title: 'Pass Not Active',
        description: 'Please try again when the pass is active',
        iconName: ErrorIconEnum.notYetActive,
        routeName: 'not-yet-active',
        isDefault: false
    },
];
