import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

//constants
import {ENV} from './env.token';
import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {MainComponent} from './components/main/main.component';
import {LoaderComponent} from './components/loader/loader.component';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {AppRoutingModule} from "./app-routing.module";
import {LoaderService} from "./services/loader.service";
import {LoaderInterceptorService} from "./services/loader-interceptor.service";
import {DialogComponent} from './components/dialog/dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SuccessDialogComponent} from './components/success-dialog/success-dialog.component';
import {OopsComponent} from './components/oops/oops.component';
import {LayoutComponent} from './components/layout/layout.component';
import {EntryCodeComponent} from './components/entry-code/entry-code.component';
import {SafePipe} from './pipes/safe.pipe';
import {PassStatusComponent} from './components/pass-status/pass-status.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {GeolocationComponent} from './components/geolocation/geolocation.component';
import {FormsModule} from "@angular/forms";
import {FooterComponent} from './components/footer/footer.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoaderComponent,
        ErrorPageComponent,
        DialogComponent,
        SuccessDialogComponent,
        OopsComponent,
        LayoutComponent,
        EntryCodeComponent,
        SafePipe,
        PassStatusComponent,
        GeolocationComponent,
        FooterComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        HttpClient,
        LoaderService,
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
        {provide: ENV, useValue: environment}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
