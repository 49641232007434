<div class="dialog">
    <h1 mat-dialog-title>{{dialogData.title}}</h1>
    <span class="close" data-dismiss="modal">
            <span aria-hidden="true" aria-label="close" (click)="close()">&times;</span>
    </span>
    <div mat-dialog-content>
        <span innerHTML="{{dialogData.text}}"></span>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="dialogData.showCancelButton"
            type="button"
            mat-raised-button
            class="btn-secondary"
            mat-dialog-close
            (click)="close()"
        >
            Cancel
        </button>
        <button *ngIf="dialogData.confirmButtonText"
            type="button"
            mat-raised-button
            class="btn-primary"
            mat-dialog-close
            (click)="onConfirm()"
        >
            {{dialogData.confirmButtonText}}
        </button>
    </div>
</div>
