import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-entry-code',
    templateUrl: './entry-code.component.html',
    styleUrls: ['./entry-code.component.scss']
})
export class EntryCodeComponent {
    @Input() entryCode: string = '';
}
