<div class="dialog" [ngSwitch]="dockPassType">
  <ng-container *ngSwitchCase="DockPassTypeEnum.checkin">
    <span class="close" data-dismiss="modal">
            <span aria-hidden="true" aria-label="close" (click)="close()">&times;</span>
      </span>
    <div mat-dialog-content class="text-centered">
      <p>Request to unlock entrance was initiated successfully</p>
      <p>Continue to the next step to receive your trailer assignment</p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="DockPassTypeEnum.checkout">
    <span class="close" data-dismiss="modal">
            <span aria-hidden="true" aria-label="close" (click)="close()">&times;</span>
      </span>
    <div mat-dialog-content class="text-centered">
      <p>Request to unlock exit was initiated successfully</p>
      <p>Continue to the next step to complete Check Out</p>
    </div>
  </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="image">
        <img class="guest-pass-img" src="../../../assets/success.svg" alt="Success">
      </div>
      <h1 mat-dialog-title class="text-centered">Success!</h1>
      <span class="close" data-dismiss="modal">
            <span aria-hidden="true" aria-label="close" (click)="close()">&times;</span>
      </span>
      <div mat-dialog-content class="text-centered">
        <span>Request to unlock entrance was initiated successfully</span>
      </div>
    </ng-container>
</div>
