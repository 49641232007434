import {Component, OnInit} from '@angular/core';
import {errorsConstant} from "../../constants/errors.constant";
import {IErrorPage} from "../../interfaces/error-page.interface";
import {ActivatedRoute} from "@angular/router";
import {ErrorCodeEnum} from "../../enums/error-code.enum";

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
    chosenAccessPointName: string = '';
    entryCode: string = '';
    currentError: any;
    errors = errorsConstant;
    errorCodesEnum = ErrorCodeEnum;
    defaultError = errorsConstant[0];

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const errorName = this.route.snapshot.paramMap.get('errorName');

        this.chosenAccessPointName = localStorage.getItem('accessPointName') || '';
        this.entryCode = localStorage.getItem('entryCode') || '';

        if (errorName) {
            const currentErr = this.errors.find(err => err.routeName === errorName) || this.defaultError;

            this.currentError = this.getParsedError({...currentErr});
        } else {
            this.currentError = this.defaultError;
        }
    }

    private getParsedError(error: IErrorPage): IErrorPage {
        if (error.wordToReplace && this.chosenAccessPointName) {
            const replaceWord = new RegExp(error.wordToReplace, 'gi');

            error.description = error.description.replace(replaceWord, this.chosenAccessPointName);
        }

        return error;
    }
}
