import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IDialogData} from "../../interfaces/dialog-data.interface";

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    public dialogData: IDialogData;

    constructor(@Inject(MAT_DIALOG_DATA) private readonly data: IDialogData,
                public dialogRef: MatDialogRef<DialogComponent>) {
        this.dialogData = data;
    }

    close(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
