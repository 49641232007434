import {Component, OnInit} from '@angular/core';
import {LoaderService} from "../../services/loader.service";

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    public isShow: boolean = true;

    constructor(public loaderService: LoaderService) {
    }

    ngOnInit(): void {
        this.loaderService.isLoading.subscribe((isShow: boolean) => {
            this.isShow = isShow;
        });
    }

}
