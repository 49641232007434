import {Component, Inject} from '@angular/core';
import {ENV} from "../../env.token";
import {IEnvironment} from "../../interfaces/environment.interface";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {
    public version: string = '';

    constructor(@Inject(ENV) private readonly environment: IEnvironment) {
        this.version = environment.version;
    }
}
