import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "./components/main/main.component";
import {ErrorPageComponent} from "./components/error-page/error-page.component";
import {OopsComponent} from "./components/oops/oops.component";
import {LayoutComponent} from "./components/layout/layout.component";
import {DockPassTypeEnum} from "./enums/dock-pass-type.enum";

const appRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: ':id',
                component: MainComponent,
                children: [
                  {
                    path: 'checkin',
                    component: MainComponent,
                    data: {
                      dockPassType: DockPassTypeEnum.checkin,
                    },
                  },
                  {
                    path: 'checkout',
                    component: MainComponent,
                    data: {
                      dockPassType: DockPassTypeEnum.checkout,
                    },
                  },
                ],
            },
            {
                path: ':id/oops/:serialNumber',
                component: OopsComponent
            },
            {
                path: ':id/error/:errorName',
                component: ErrorPageComponent,
            },
            {
                path: '**',
                component: ErrorPageComponent
            }
        ]
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
