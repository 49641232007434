import {Component, OnInit} from '@angular/core';
import {PassStatusEnum} from "../../enums/pass-status.enum";

@Component({
    selector: 'app-pass-status',
    templateUrl: './pass-status.component.html',
    styleUrls: ['./pass-status.component.scss']
})
export class PassStatusComponent implements OnInit {
    passStatusEnum = PassStatusEnum;
    activeStartDate: string = '';
    currentPassStatus: string = '';

    ngOnInit(): void {
        this.activeStartDate = localStorage.getItem('activeStartDate') || '';
        this.currentPassStatus = localStorage.getItem('currentPassStatus') || '';
    }
}
