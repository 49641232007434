import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {DialogComponent} from "../dialog/dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataService} from "../../services/data.service";

export enum geolocationPermissionEnum {
    granted = 'granted',
    prompt = 'prompt',
    denied = 'denied'
}

@Component({
    selector: 'geolocation',
    templateUrl: './geolocation.component.html',
    styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnDestroy {
    @Input() public locationRadius: number | undefined = 0;
    @Input() public facilityLatitude: number | undefined = 0;
    @Input() public facilityLongitude: number | undefined = 0;
    @Input() public facilityAddress: string | undefined = '';

    @Input() public withinRadius: boolean = false;
    @Output() withinRadiusChange = new EventEmitter<boolean>();

    locationServicesChecked: boolean = false;
    private watchID: number = 0;

    constructor(public dialog: MatDialog,
                private dataService: DataService) {
    }

    public ngOnDestroy(): void {
        this.clearWatch();
    }

    onLocationChange(): void {
        const locationPermissions = geolocationPermissionEnum;

        if (!this.locationServicesChecked) {
            this.setWithinRadiusValue(false);
            this.clearWatch();
        } else {
            navigator.permissions.query({name: 'geolocation'}).then((result: PermissionStatus) => {
                if (result.state === locationPermissions.granted) {
                    this.trackPosition();
                } else if (result.state === locationPermissions.prompt) {
                    this.showAccessPermissionModal();
                } else if (result.state === locationPermissions.denied) {
                    this.locationServicesChecked = false;
                    this.revokePermissionModal();
                }
            });
        }
    }

    public entranceCanBeOpened(): boolean {
        return this.locationServicesChecked && this.withinRadius;
    }

    public showNotInProximityModal(): void {
        this.dialog.open(DialogComponent, {
            ...this.dataService.dialogConfig, ...{
                data: {
                    title: 'Not In Proximity',
                    text: `You are not in close proximity to unlock the entrance. Please try again when you are in close proximity to <b>${this.facilityAddress}</b>.`,
                    showCancelButton: true,
                }
            }
        });
    }

    private trackPosition(): void {
        if (navigator.geolocation) {
            const onSuccess = (position: GeolocationPosition) => {
                const distance = this.calcCrow(position.coords.latitude, position.coords.longitude);
                const isWithinRadius = this.locationRadius && distance <= this.locationRadius || false;

                this.setWithinRadiusValue(isWithinRadius);
            }

            const onError = () => {
                this.locationServicesChecked = false;
            }

            const options = {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 15000
            };

            this.watchID = navigator.geolocation.watchPosition(onSuccess, onError, options);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    private setWithinRadiusValue(value: boolean): void {
        this.withinRadius = value;
        this.withinRadiusChange.emit(value);
    }

    private clearWatch(): void {
        if (this.watchID) {
            navigator.geolocation.clearWatch(this.watchID);
            this.watchID = 0;
        }
    }

    private showAccessPermissionModal(): void {
        const modal: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent, {
            ...this.dataService.dialogConfig, ...{
                data: {
                    title: 'Allow “Myq Community” to access your location?',
                    text: 'Your location will be used to access entryways when in close proximity.',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm'
                }
            }
        });

        modal.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.trackPosition();
            } else {
                this.locationServicesChecked = false;
            }
        });
    }

    private revokePermissionModal(): void {
        this.dialog.open(DialogComponent, {
            ...{
                maxWidth: '295px',
                width: '80vw',
            }, ...{
                data: {
                    title: 'Go to Browser Settings',
                    text: 'To re-enable location services, go to your browser settings to allow location access.',
                    showCancelButton: false,
                }
            }
        });
    }

    private calcCrow(currentLatitude: number, currentLongitude: number): number {
        if (this.facilityLongitude && this.facilityLatitude) {
            const R = 3961; // miles
            const dLat = this.toRad(this.facilityLatitude - currentLatitude);
            const dLon = this.toRad(this.facilityLongitude - currentLongitude);
            const lat1 = this.toRad(currentLatitude);
            const lat2 = this.toRad(this.facilityLatitude);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c;
            return d;
        }

        return -1;
    }

    private toRad(value: number): number {
        return value * Math.PI / 180;
    }
}
