import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {ENV} from "../env.token";
import {IEnvironment} from "../interfaces/environment.interface";
import {Observable} from "rxjs";
import {IEvent} from "../interfaces/event.interface";
import {IErrorPage} from "../interfaces/error-page.interface";
import {errorsConstant} from "../constants/errors.constant";
import {Data, Router} from "@angular/router";
import {ErrorCodeEnum} from "../enums/error-code.enum";
import {IAccessPoint} from "../interfaces/access-point.interface";
import {PassStatusEnum} from "../enums/pass-status.enum";
import {DockPassTypeEnum} from "../enums/dock-pass-type.enum";
import {IDockPass} from "../interfaces/dock-pass.interface";

@Injectable({
    providedIn: 'root'
})
export class GuestEventService {
    private readonly httpOptions: object;
    private readonly errors = errorsConstant;
    private readonly errorCodesEnum = ErrorCodeEnum;
    private readonly passStatusEnum = PassStatusEnum;

    constructor(private router: Router,
                private readonly http: HttpClient,
                @Inject(ENV) private readonly environment: IEnvironment) {
        this.httpOptions = {
            headers: {
                'Accept-Language': 'en-US,en',
                'Cache-Control': 'no-store',
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json, text/plain, */*',
                Pragma: 'no-cache'
            },
            observe: 'response',
        };
    }

    getEventDetails(eventId: string, passType?: Data | IDockPass): Observable<IEvent> {
        let url = `${this.environment.appApis.guestAccess}/api/v6/events/${eventId}`;
        if (passType && passType.dockPassType) {
          url = `${url}/${passType.dockPassType}`;
        }

        return this.http.get(url, this.httpOptions)
            .pipe(map((response: any) => {
                return response.body;
            }));
    }

    unlockDevice(eventId: string, serialNumber: string): Observable<any> {
        return this.http.post(`${this.environment.appApis.guestAccess}/api/v6/events/${eventId}/devices/${serialNumber}`, null, this.httpOptions);
    }

    handleError(passId: string, errCode: string, device?: IAccessPoint, passStatus?: string) {
        const defaultError = this.errors.find(error => error.isDefault) || this.errors[0];
        const currentError: IErrorPage | undefined = passStatus && passStatus === this.passStatusEnum.notYetActive ?
            this.errors.find(error => error.code === this.errorCodesEnum.passNotActive) :
            this.errors.find(error => error.code === errCode) || defaultError;
        const oopsErrorCode = this.errorCodesEnum.oops;

        this.router.navigate(errCode === oopsErrorCode && device ?
            [`${passId}/oops/`, device.serial_number] :
            [`${passId}/error/` + currentError?.routeName || defaultError.routeName]
        );
    }
}
