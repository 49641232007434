<div class="geolocation-control">
    <span class="geolocation-control__label">Location Services</span>
    <label class="switch">
        <input type="checkbox" [(ngModel)]="locationServicesChecked" (change)="onLocationChange()">
        <span class="slider round"></span>
    </label>
</div>

<p class="description">
    Allow myQ Community to use your current location to use the “tap to enter” feature
</p>
