<div class="pass-status" [ngClass]="{'expired': currentPassStatus === passStatusEnum.expired,
            'active': currentPassStatus === passStatusEnum.active,
            'not-yet-active': currentPassStatus === passStatusEnum.notYetActive}">
    <span *ngIf="currentPassStatus === passStatusEnum.expired">Pass Expired</span>
    <span *ngIf="currentPassStatus === passStatusEnum.active">Pass Active</span>
    <div *ngIf="currentPassStatus === passStatusEnum.notYetActive">
        <span>Pass will be active on</span><br>
        <span class="active-date-text">{{activeStartDate}}</span>
    </div>
</div>
