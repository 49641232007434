<div *ngIf="!loaderService.isLoading.getValue()">
    <div class="main-content main-content--commercial">
        <div class="main-card">

            <div class="main-card__header">
                <h2 *ngIf="!isCommercial; else commercialTitle" class="title title--community">
                    Welcome to <br>{{event?.community?.name}}{{event?.guest?.name ? ', ' + event?.guest?.name + '!' : ''}}
                </h2>

                <ng-template #commercialTitle>
                    <img class="guest-pass-img" src="../../../assets/truck.svg" alt="Truck illustration">
                    <h2 class="title">Welcome to {{event?.community?.name}}</h2>
                </ng-template>
            </div>

            <ng-container *ngIf="!isCommercial; else commercialSubTitle">
                <p class="sub-title" >{{event?.invited_by?.first_name}} {{event?.invited_by?.last_name}} has sent you a
                    guest pass for {{event?.community?.name}}.</p>
                <img class="powered-by-myq-img" src="../../../assets/powered_by_myq.svg" alt="Powered by myQ">
            </ng-container>
            <ng-template #commercialSubTitle>
              <p class="sub-title">{{event?.invited_by?.first_name}} {{event?.invited_by?.last_name}} has sent you a
                myQ Docking Pass™. To {{ dockPassType === dockPassTypeEnum.checkout ? 'exit' : 'enter' }} {{event?.community?.name}} tap the button once you are near the entrance.</p>
            </ng-template>

            <div *ngIf="!isCommercial" class="full-width">
                <app-pass-status></app-pass-status>
            </div>

            <div class="entrance-container" [ngClass]="{'no-entrances': isEntryCodeAccessOnly}">
                <ng-container *ngFor="let accessPoint of event?.access_points">
                    <div *ngIf="!accessPoint.is_entry_code_access_only" class="entrance-block"
                         [ngClass]="{'disabled': currentPassStatus === passStatusEnum.expired || (geolocationFeature && !withinRadius)}"
                         (click)="onDeviceClick(accessPoint)">
                        <img src="../../../assets/icons/{{accessPoint.kind === accessPointKindEnum.elevator ? 'elevator.svg' : 'lock.svg'}}" alt="Tap to Enter {{accessPoint.name}}"
                             title="{{accessPoint.name}}" class="lock-icon">
                        <div *ngIf="!isCommercial"><b>Tap to {{accessPoint.kind === accessPointKindEnum.elevator ? 'Enable' : 'Enter'}}</b></div>
                      <div *ngIf="isCommercial" class="instruction-text"><b>Tap to Open</b></div>
                        <div><span class="access-point-name">{{accessPoint.name}}</span></div>
                    </div>
                </ng-container>
            </div>

            <geolocation *ngIf="geolocationFeature"
                         [locationRadius]="event?.config?.location_radius"
                         [facilityLatitude]="event?.community?.latitude"
                         [facilityLongitude]="event?.community?.longitude"
                         [facilityAddress]="event?.community?.address?.address_line1"
                         [(withinRadius)]="withinRadius"
            ></geolocation>

            <div *ngIf="isCommercial" class="full-width">
                <app-pass-status></app-pass-status>
            </div>

            <div *ngIf="isCommercial">
              <p class="entry-code-text">
                To use the entry code, make sure you select “I have an entry code” on the screen or enter the code directly into the keypad.
              </p>
            </div>

            <app-entry-code [entryCode]="event?.entry_code || ''"></app-entry-code>

            <div *ngIf="!isCommercial">
                <p *ngIf="!isEntryCodeAccessOnly" class="help-link" (click)="onEntryCodeHelpLink()">How do I use this code?</p>
            </div>

            <div class="details-container">
                <div class="detail-container">
                    <img src="../../../assets/icons/entrance-dark.svg" alt="Entrances" *ngIf="!isCommercial; else commercialEntranceIcon">
                    <ng-template #commercialEntranceIcon>
                      <img src="../../../assets/icons/gate.svg" alt="Entrances">
                    </ng-template>
                    <p>{{ event?.uiAccessPoints }}</p>
                </div>

                <div class="detail-container" *ngIf="!isCommercial && event?.recurrence?.recurrence_type === recurrenceTypeEnum.weekly">
                    <img src="../../../assets/icons/calendar-dark.svg" alt="Week days">
                    <p>{{event?.uiWeeks}}</p>
                </div>

                <div class="detail-container active-period-info">
                    <img *ngIf="!isCommercial; else commercialClockIcon" src="../../../assets/icons/clock_dark.svg" alt="Recurrence Information">
                    <ng-template #commercialClockIcon>
                        <img src="../../../assets/icons/clock.svg" alt="Recurrence Information">
                    </ng-template>
                    <div>
                        <p *ngIf="isCommercial">Pass active</p>

                        <div *ngIf="!isCommercial; else commercialTimeInfo">
                            <p>Starts: {{event?.uiStartDate}} <br> {{event?.uiStartTime}} {{event?.uiTimeZone}}</p>
                            <p *ngIf="event?.type !== passTypeEnum.delivery">Ends: {{event?.uiEndDate}} <br> {{event?.uiEndTime}} {{event?.uiTimeZone}}</p>
                            <p *ngIf="event?.type === passTypeEnum.delivery">{{event?.uiDeliveryRemainingDaysStr}}</p>
                        </div>

                        <ng-template #commercialTimeInfo>
                            <div *ngIf="event?.recurrence?.recurrence_type === recurrenceTypeEnum.once">
                                <p>{{event?.oldUITimeZone}}</p>
                                <p>Starts: {{event?.oldUIStartDate}}</p>
                                <p *ngIf="event?.type !== passTypeEnum.delivery">Ends: {{event?.oldUIEndDate}}</p>
                                <p *ngIf="event?.type === passTypeEnum.delivery">
                                    {{event?.uiDeliveryRemainingDaysStr}}
                                </p>
                            </div>

                            <div *ngIf="event?.recurrence?.recurrence_type === recurrenceTypeEnum.weekly">
                                <p>{{event?.uiWeeks}}</p>
                                <p>{{event?.oldUITimeZone}}</p>
                                <p>Start Date: {{event?.recurrence?.start_date | date}}</p>
                                <p>End Date: {{event?.recurrence?.end_date | date}}</p>
                                <p>Start Time: {{event?.uiStartTime}}</p>
                                <p>End Time: {{event?.uiEndTime}}</p>
                            </div>
                        </ng-template>

                        <div>
                            <div class="map-container">
                                <iframe
                                    class="iframe"
                                    loading="lazy"
                                    [src]="addressStr | safe">
                                </iframe>
                            </div>
                            <p class="address-text">
                                {{event?.community?.address?.address_line1}} {{event?.community?.address?.city}},
                                {{event?.community?.address?.state}} {{event?.community?.address?.postal_code}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom-container">
            <div *ngIf="!isCommercial; else commercialBottomInfo">
                <p>Once you use this guest pass, the event will be logged and visible
                    to {{event?.invited_by?.first_name}} {{event?.invited_by?.last_name}} and the Property Manager.</p>
                <p>If you encounter issues, please contact the resident directly or by looking them up in the smart
                    intercom directory.</p>
            </div>
          <ng-template #commercialBottomInfo>
              <div>
                  <p>Once you use this pass, the event will be logged and visible to the Facility Manager</p>
                  <p>If you encounter issues, please contact the facility directly.</p>
              </div>
          </ng-template>
        </div>
    </div>
    <app-footer></app-footer>
</div>


