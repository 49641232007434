import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GuestEventService} from "../../services/guest-event.service";
import {SuccessDialogComponent} from "../success-dialog/success-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "../../services/data.service";
import {errorsConstant} from "../../constants/errors.constant";

@Component({
    selector: 'app-oops',
    templateUrl: './oops.component.html',
    styleUrls: ['./oops.component.scss']
})
export class OopsComponent implements OnInit {
    public entryCode: string = '';
    public errors = errorsConstant;

    private passId: string = '';
    private serialNumber: string = '';

    constructor(
        public dialog: MatDialog,
        private guestEventService: GuestEventService,
        private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit(): void {
        const routeParams = this.route.snapshot.paramMap;

        this.entryCode = localStorage.getItem('entryCode') || '';
        this.passId = routeParams.get('id') || '';
        this.serialNumber = routeParams.get('serialNumber') || '';
    }

    onTapToUnlock(): void {
        if (this.passId && this.serialNumber) {
            this.guestEventService.unlockDevice(this.passId, this.serialNumber).subscribe(
                () => {
                    this.router.navigate(['../..'], {relativeTo: this.route});
                    this.dialog.open(SuccessDialogComponent, this.dataService.successDialogConfig);
                },
                (err) => this.guestEventService.handleError(this.passId, err.error.code));
        }
    }
}
