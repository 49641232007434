<div class="main-content">
    <div class="main-card">
        <img *ngIf="currentError.code === errorCodesEnum.unableToUnlock" routerLink="../.." class="back-button" src="../../../assets/icons/back.svg" alt="Back">

        <img class="guest-pass-img" src="../../../assets/{{currentError.iconName}}.svg" alt="Error">
        <h2 class="title">{{currentError?.title || defaultError?.title}}</h2>
        <p class="sub-title">{{currentError?.description || defaultError?.description}}</p>

        <div *ngIf="currentError.code === errorCodesEnum.unableToUnlock && entryCode" class="entry-code-wrapper">
            <app-entry-code [entryCode]="entryCode"></app-entry-code>
        </div>
        <div *ngIf="currentError.code === errorCodesEnum.passNotActive" class="full-width">
            <app-pass-status></app-pass-status>
        </div>
    </div>
</div>

<app-footer></app-footer>

