import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DockPassTypeEnum} from "../../enums/dock-pass-type.enum";

@Component({
    selector: 'app-success-dialog',
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  public dockPassType: DockPassTypeEnum;
  public readonly DockPassTypeEnum = DockPassTypeEnum;

    constructor(@Inject(MAT_DIALOG_DATA) private readonly data: any,
                public dialogRef: MatDialogRef<SuccessDialogComponent>) {
      this.dockPassType = data?.dockPassType;
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
